jQuery(function ($) {
  jQuery(document).ready(function () {
    if (!$('.contact-modal-btn').length) return;
    var maskOptions = {
      mask: "(000) 000-0000 [Ext. 0000000]",
    };
    var phoneMask = IMask(document.getElementById("phone"), maskOptions);

    $(".contact-modal-btn").click(function () {
      var data_post = {};
      var flag = true;
      $("input[required],textarea[required]").each(function (index) {
        $(this).removeClass("error");
        $(this).next(".error_text").hide();
        $(this).siblings("label").removeClass("error");
        var value = "";
        var error_label = "Required";
        if ($(this).attr("type") == "email") {
          var validRegex =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
          if ($(this).val().match(validRegex)) {
            value = $(this).val();
          } else if ($(this).val()) {
            error_label = "Invalid";
          }
        } else if ($(this).attr("type") == "tel") {
          var validRegex = /^\([0-9]{3}\) [0-9]{3}-[0-9]{4}( Ext\. )?(\d+)?$/;
          if ($(this).val().match(validRegex)) {
            value = $(this).val();
          } else if ($(this).val()) {
            error_label = "Invalid";
          }
        } else if (
          $(this).attr("type") == "checkbox" ||
          $(this).attr("type") == "radio"
        ) {
          value = $(this).prop("checked");
          if (value && $(this).attr("type") == "radio") {
            value = $(this).val();
          }
        } else {
          value = $(this).val();
        }
        if (value == "") {
          flag = false;
          $(this).addClass("error");
          $(this).siblings("label").addClass("error");
          $(this).next(".error_text").html(error_label);
          $(this).next(".error_text").show();
        }
      });
      if (!flag) return;
      jQuery(".form input,.form textarea").each(function (index) {
        var value = "";
        if (
          $(this).attr("type") == "checkbox" ||
          $(this).attr("type") == "radio"
        ) {
          value = $(this).prop("checked");
          if (value && $(this).attr("type") == "radio") {
            value = $(this).val();
          }
        } else {
          value = $(this).val();
        }
        if (value) {
          data_post[$(this).attr("name")] = value;
        }
      });

      $('body').loading_screen(true, function () { });

      ThemeJS.Functions.send_ajax_request({
        data: {
          action: "contact_ajax_action",
          ...data_post,
        },
        success: (response) => {
          window.location = ThemeJS.Variables.Base_Url + "/thank-you";
          // $('body').loading_screen( false, function() { } );
        },
        error: (response) => {
          $('body').loading_screen(false, function () { });
        },
      });
    });
  });
});