jQuery(function ($) {
    jQuery(document).ready(function () {
        $( '.the_tank_image' ).on( 'click', function() {
            var theTarget = $( this ).data( 'target' );
            var $theParent = $( this ).parent();
            $( '.tank_image' ).removeClass( 'active' );
            $( '.description' ).removeClass( 'active' );

            $theParent.addClass( 'active' );
            $( '.' + theTarget ).addClass( 'active' );
        } ) ;
    });
  });