jQuery(function ($) {
  jQuery(document).ready(function () {
    //hide all of the answer
    $(".answer:not(.show)").hide();

    $(".nav-link").click(function () {
      $(".question").attr("style", "display:flex !important");
    });

    $("#searh_input").keyup(function (event) {
      var search_value = $("#searh_input").val();
      $(".tab-pane").addClass("show");
      $(".question").attr("style", "display:flex !important");
      $(".no_result").hide();
      if (!search_value) return;
      $(".question").each(function () {
        var question_test = $(this).find(".question_text")[0].innerHTML;
        var answer_test = $(this).find(".answer_text")[0].innerHTML;
        if (
          !question_test.toLocaleLowerCase().includes(search_value) &&
          !answer_test.toLocaleLowerCase().includes(search_value)
        ) {
          $(this).attr("style", "display:none !important");
          $(this).hide();
        }
      });
      if (!$(".question").is(":visible")) {
        $(".no_result").show();
      }
    });

    $(".question").click(function () {

      if ($(this).find(".answer").is(":visible")) {
        // $(this).find(".answer").slideUp();
        // $(this).find('.accordion-icon.minus').addClass('d-none');
        // $(this).find('.accordion-icon.plus').removeClass('d-none');
        // // $(this).find("svg").removeClass("fa-minus").addClass("fa-plus");
        //do nothing 
      } else {
        // $(this).find(".answer").slideDown();
        // // $(this).find("svg").removeClass("fa-plus").addClass("fa-minus");
        // $(this).find('.accordion-icon.minus').removeClass('d-none');
        // $(this).find('.accordion-icon.plus').addClass('d-none');
        var $accordion = $( this ).parents( '.accordion' );
        $accordion.find( ".answer" ).slideUp();
        $accordion.find('.accordion-icon.minus').addClass('d-none');
        $accordion.find('.accordion-icon.plus').removeClass('d-none');
        console.log( $accordion );
        $(this).find(".answer").slideDown();
        $(this).find('.accordion-icon.minus').removeClass('d-none');
        $(this).find('.accordion-icon.plus').addClass('d-none');
      }
    });
  });
});
